import React, { useState } from "react"
import { Link } from "gatsby"
import error_logo from "../assets/images/error_icon.png"
import info_logo from "../assets/images/info_icon.png"
import path_img from "../assets/images/studydetail/path-1179@1x.png"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { useMutation,useApolloClient} from "@apollo/client"
import {
    UPDATE_USER_FORGOT_PASSWORD_CODE_MUTATION,
    GET_USER_FORGOT_PASS_CODE,
    UPDATE_USER_PASSWORD_MUTATION
} from "../components/graphql"
import {validatePassword } from "../../utils"
import BounceIndicator from "react-activity/lib/Bounce"
import axios from "axios";

function ForgotPassword(props){
    const client = useApolloClient()
    const [loader, setLoader] = useState(false)
    const query = new URLSearchParams(props.location.search);
    // const code =query.get('code')
    const [pass, setPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [email, setEmail] = useState(query.get('email'))
    const [passError, setPassError] = useState("")
    const [confirmPassError, setConfirmPassError] = useState("")
    const [success,setSuccess] = useState("")
    const [error,setError] = useState("")
    const [updateUser] = useMutation(UPDATE_USER_FORGOT_PASSWORD_CODE_MUTATION)
    const [updatePassword] = useMutation(UPDATE_USER_PASSWORD_MUTATION)


    const changePassword = () =>{
        setLoader(true)
        setError("")
        setSuccess("")
        if(!validatePassword(pass)){
            setPassError("Enter a valid password")
            setLoader(false)
        }
        if(!validatePassword(confirmPass)){
            setConfirmPassError("Enter a valid confirm password")
            setLoader(false)
        }
        if(pass !== confirmPass){
            setConfirmPassError("Password and confirm password doesn't match")
            setLoader(false)
        }
        if(validatePassword(pass) && validatePassword(confirmPass) && pass === confirmPass){
            setPassError("")
            setConfirmPassError("")
            if(email && query.get('code')){
                client
                .query({
                query: GET_USER_FORGOT_PASS_CODE,
                variables: { email: email },
                })
                .then(res => {
                    if(res.data){
                        console.log("resp",res.data)
                        if(res.data.users[0].forgetPasswordCode === query.get('code')){
                            updatePassword({
                                variables: {
                                    userId:res.data.users[0].id,
                                    pwd: pass
                                },
                            })
                            .then(response => {
                                if(response.data){
                                    axios({
                                      method: 'get',
                                      url: `${process.env.NODEBB_URL}/api/user/email/${email}`,
                                      headers: { 
                                        "_uid":"1",
                                        "Authorization":`Bearer ${process.env.NOBEBB_TOKEN}`
                                      }
                                    })
                                    .then(function (response) {
                                        console.log("11111111111111111111111111111####",response);
                                        if(response){
                                            if(response.data){
                                               let uid = response.data.uid
                                               if(uid){
                                                    axios({
                                                      method: 'put',
                                                      url: `${process.env.NODEBB_URL}/api/v3/users/${uid}/password`,
                                                      data:{
                                                        "_uid":uid,
                                                        "password": pass,
                                                        "newPassword":pass
                                                      },
                                                      headers: { 
                                                        "_uid":"1",
                                                        "Authorization":`Bearer ${process.env.NOBEBB_TOKEN}`
                                                    }})
                                                    .then(function (response) {
                                                        console.log("222222222222222222########")
                                                    })
                                                    .catch(function (error) {
                                                        console.log(error);
                                                    })
                                                }
                                            }
                                        }
                                    })
                                    .catch(function (error) {
                                      console.log(error);
                                    })
                                    updateUser({
                                        variables: {
                                            id:response.data.updateUser.user.id,
                                            code: ""
                                        },
                                    })
                                    .then(response => {
                                        if(response.data){
                                            setLoader(false)
                                            setPass("")
                                            setConfirmPass("")
                                            setSuccess("Password saved Successfully")
                                        }
                                    }) 
                                }
                            })
                        }
                        else{
                            setLoader(false)
                            setError("Invalid token")
                        }
                    }
                })
            }
            else{
                setLoader(false)
                setError("Please try again")
            }
        }
    }

    return(
        <Layout setTitle={"Forgot Password"}>
        <SEO title="Forgot Password"/>
        <>
            <main className="contactus">
                <div className="main-content">
                <div className="container">
                    <div className="breadcrumbs">
                        <div className="smalltext">
                            <img src={path_img} alt=""/>
                            <Link to="/">/ forgot password</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-contact">
                            <div className="form">
                                <div className="row forgot">
                                    <div className="col-md-12">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            // autoFocus
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={e => {
                                            setEmail(e.target.value)
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            placeholder="Password"
                                            value={pass}
                                            onChange={e => {
                                            setPass(e.target.value)
                                            }}
                                        />
                                        {passError !== ""?(
                                        <>
                                            <span className="error-message">
                                                <img src={error_logo} alt="" /> {passError}
                                            </span>
                                        </>
                                        ) :  
                                            <span className="w-message">
                                                <img src={info_logo} alt="" /> Use of minimum 7 characters
                                            </span>
                                        }
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="c_password">Confirm Password</label>
                                        <input
                                            type="password"
                                            id="c_password"
                                            placeholder="Confirm Password"
                                            value={confirmPass}
                                            onChange={e => {
                                                setConfirmPass(e.target.value)
                                            }}
                                            />
                                        {confirmPassError !== ""? (
                                            <span className="error-message">
                                                <img src={error_logo} alt="" /> {confirmPassError}
                                            </span>
                                            ) : null}
                                    </div>
                                    {success? <span className="success-message">{success}</span>:""}
                                    {error? <span className="error-message">{error}</span>:""}
                                    <div className="submit">
                                        <button
                                            style={{width:"auto"}}
                                            onClick={() => {
                                            changePassword()
                                            }}
                                        >
                                        
                                            {loader === false ? (
                                                "Reset Password"
                                            ) : (
                                                <div className={"centered"} style={{ height: "14px" }}>
                                                <BounceIndicator size={14} color={"#FFF"} />
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        </>
        </Layout>
    )
}
export default ForgotPassword